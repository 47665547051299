import type { TItem, TDirections } from './types'

export const items: Record<TDirections, TItem> = {
  management: {
    title: 'Проджект-менеджмент',
    subtitle:
      'Научишься управлять проектами на всех этапах — от коммуникации с заказчиком и сборки прототипа до реализации и поддержки.',
    program: [
      'Управленческие фреймворки',
      'Роли в команде',
      'Релиз-менеджмент',
      'Командная эффективность',
      'Управленческие артефакты',
      'Работа с третьими сторонами',
      'Коммуникация с заказчиками',
    ],
  },
  design: {
    title: 'UI/UX-дизайн',
    subtitle:
      'Погрузишься в настоящую разработку дизайна приложений и научишься создавать интерфейсы мобильных продуктов.',
    program: [
      'Исследование рынка',
      'Структура мобильного приложения',
      'Логика',
      'User Experience',
      'Проектирование вайрфреймов',
      'Улучшение пользовательского опыта',
      'User Interface',
      'Концепт',
      'UI-киты',
      'Дизайн-системы',
      'Userflow',
    ],
  },
  analytics: {
    title: 'Аналитика',
    subtitle:
      'Научишься работать с информацией, делать прогнозы на основе данных и помогать принимать решения в бизнесе.',
    program: [
      'Решение задач',
      'Анализ данных',
      'Системный подход',
      'Работа с информацией',
      'Алгоритмы',
      'Прогнозирование изменений',
      'Визуализация данных',
      'Проверка гипотез',
    ],
  },
  ios: {
    title: 'iOS-разработка',
    subtitle:
      'Освоишь основы разработки, пройдешь путь от создания проекта до публикации приложения.',
    program: [
      'Основы разработки на iOS',
      'Пользовательский интерфейс',
      'Архитектура мобильных приложений',
      'Реализация функциональности',
      'Работа с сетью и многопоточностью',
      'Хранение данных и уведомления',
      'Публикация в App Store',
    ],
  },
  android: {
    title: 'Android-разработка',
    subtitle:
      'Освоишь разработку на Kotlin под самую популярную мобильную платформу.',
    program: [
      'Разработка на Kotlin',
      'Введение в RxJava',
      'MVI-архитектура',
      'Custom Clean Architecture',
      'Декларативный UI (вёрстка без XML)',
      'SQL базы данных',
      'Практики CI/CD',
      'Kotlin Configuration Plugin',
      'Conductor',
    ],
  },
  frontend: {
    title: 'Frontend-разработка',
    subtitle:
      'Научишься проектировать интерфейсы и создавать эффектные цифровые продукты на React.',
    program: [
      'Преимущества разработки на TypeScript',
      'Работа с состоянием приложения (Redux, Effector)',
      'Кросс-платформенная разработка на React Native',
      'Атомарный дизайн',
      'Изолированная разработка UI-компонентов (Storybook)',
      'Паттерны проектирования',
    ],
  },
  backend: {
    title: 'Backend-разработка',
    subtitle:
      'Заглянешь в закулисье web и узнаешь, что на самом деле находится по ту сторону клиентских приложений.',
    program: [
      'Архитектурные подходы',
      'Организация хранения данных',
      'Асинхронная обработка и параллелизм',
      'Сетевые протоколы и API',
      'Аутентификация и авторизация',
      'Test driven development',
      'Роли в команде и взаимодействие',
    ],
  },
  qa: {
    title: 'QA',
    subtitle:
      'Получишь всё для старта карьеры тестировщика и сможешь обеспечивать качество цифрового продукта на всех этапах разработки.',
    program: [
      'Жизненный цикл разработки ПО и роль QA в нем',
      'Тестовая документация',
      'Процессы ревью QA инженером',
      'Клиент-серверное взаимодействие, тестирование API, Postman, SOAP UI',
      'Применение Charles для локализации ошибок',
      'Дополнительные инструменты тестирования (adb, ChromeDevTools)',
      'Локализация ошибок с помощью системы логгирования ELK',
    ],
  },
  recruiting: {
    title: 'Рекрутинг',
    subtitle:
      'Погрузишься в специфику IT-рынка и поймёшь, как искать идеальных кандидатов: от обработки запроса — до отправления оффера.',
    program: [
      'Основы рекрутинга в IT',
      'Объекты поиска',
      'Основные методы поиска',
      'Коммуникация',
      'Интервью',
      'Оценка soft skills',
      'Оффер и возражения',
      'Адаптация новичков',
    ],
  },
  vui: {
    title: 'Разговорный дизайн',
    subtitle:
      'Научишься проектировать разговорные интерфейсы и помогать бизнесу использовать ассистентов, чат-боты и телефонные системы по максимуму.',
    program: [
      'Знакомство со сферой VUI',
      'Структура голосового интерфейса',
      'Разработка идеи и концепции',
      'Набросок голосового навыка и прототипирования',
      'Работа со сценариями',
      'Промпты и аттерансы',
      'Тестирование дизайна',
      'Аналитика',
    ],
  },
}
