import React from 'react'

import { Wrapper, HBox } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'
import { Header, Body } from 'src/ui/typography'

import { items } from './items'

const Layout = styled.div`
  padding: ${({ theme }) => theme.paddings.half * 3}px 0;
  background-size: cover;
`

const Grid = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.paddings.half * 3}px;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white.primary};
  padding: ${({ theme }) => theme.paddings.main}px 0;
`

type Props = { images: TImages }

export const TrainingInfo: React.FC<Props> = ({ images }) => {
  const theme = useTheme()

  return (
    <Layout style={{ backgroundImage: `url(${images['bg'].src})` }}>
      <Wrapper>
        <Grid>
          {items.map(({ numeral, title }) => (
            <Item>
              <Header size="h3">{numeral}</Header>
              <HBox height={theme.paddings.half} />
              <Body>{title}</Body>
            </Item>
          ))}
        </Grid>
      </Wrapper>
    </Layout>
  )
}
