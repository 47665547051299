import React from 'react'

import { styled } from 'src/ui/theme'
import { Wrapper, HBox } from 'src/ui/layout'
import { Header, Body } from 'src/ui/typography'

import { items } from './items'

const StatWrapper = styled.div`
  padding: ${({ theme }) => `${theme.paddings.main * 4.5}px 0`};
  background-color: ${({ theme }) => theme.colors.gray.divider};
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: ${({ theme }) => theme.paddings.main}px;
`

const StyledHeader = styled(Header)`
  line-height: 1;
`

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

type Props = {}

export const Stat: React.FC<Props> = () => {
  return (
    <StatWrapper>
      <Wrapper>
        <Grid>
          {items.map(({ stat, title }) => (
            <StatItem>
              <StyledHeader size="h1">{stat}</StyledHeader>
              <HBox height={32} />
              <Body size="primary" style={{ textAlign: 'center' }}>
                {title}
              </Body>
            </StatItem>
          ))}
        </Grid>
      </Wrapper>
    </StatWrapper>
  )
}
