import React from 'react'

import { Wrapper, VBox } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'
import { Header } from 'src/ui/typography'
import { Image } from 'src/ui/atoms'
import { breakpoints } from 'src/ui/dimensions'

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.gray.divider};
  padding: ${({ theme }) =>
    `${theme.paddings.half * 3}px ${theme.paddings.main * 4}px`};
`

const HeaderWrapper = styled.div`
  flex: 1;

  @media (min-width: ${breakpoints.tablet}px) {
    max-width: 767px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  max-width: 381px;
  flex: 2;
`

type Props = {
  images: TImages
}

export const OfferBanner: React.FC<Props> = ({ images }) => {
  const theme = useTheme()

  return (
    <Wrapper>
      <Content>
        <HeaderWrapper>
          <Header size="h3">
            Лучшие стажёры получают приглашение на работу
          </Header>
        </HeaderWrapper>
        {typeof window !== 'undefined' && window.innerWidth >= breakpoints.tablet && (
          <>
            <VBox width={theme.paddings.half * 4.5} />
            <ImageWrapper>
              <Image fluid={images['offer-banner']} height={215} />
            </ImageWrapper>
          </>
        )}
      </Content>
    </Wrapper>
  )
}
