import React from 'react'
import { AdvantagesItem } from '../../molecules'

type AdvantagesItemProps = React.ComponentProps<typeof AdvantagesItem>

export const getItems = (images: TImages): AdvantagesItemProps[] => [
  {
    title: 'Ценный опыт работы',
    description:
      'У тебя за плечами будет практика решения реальных задач и участия в проектах ведущей IT-компании в России.',
    image: images['training-advantages-1'],
  },
  {
    title: 'Индивидуальный подход',
    description:
      'Практикующие эксперты помогут прокачать скиллы, необходимые для карьеры по твоей специальности, дадут обратную связь и покажут точки роста.',
    image: images['training-advantages-2'],
  },
  {
    title: 'Поддержка комьюнити',
    description:
      'Обучение проходит в команде увлечённых ребят, нацеленных на рост и развитие, которые будут вдохновлять на результат.',
    image: images['training-advantages-3'],
  },
]
