import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { Mentors as Desk } from './desk'
import { Mentors as Mob } from './mob'

type Props = {
  images: TImages
}
export const Mentors: React.FC<Props> = ({ images }) => {
  const { isMobile } = useDimensions()
  const Component = isMobile ? Mob : Desk

  return <Component images={images} />
}
