import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { AdvantagesItem as Desk } from './desk'
import { AdvantagesItem as Mob } from './mob'
import type { Props } from './types'

export const AdvantagesItem: React.FC<Props> = (props) => {
  const { isMobile } = useDimensions()
  const Component = isMobile ? Mob : Desk

  return <Component {...props} />
}
