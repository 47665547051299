import React from 'react'
import { FluidObject } from 'gatsby-image'

import { styled } from 'src/ui/theme'
import { Header } from 'src/ui/typography'
import { HBox } from 'src/ui/layout'
import { breakpoints } from 'src/ui/dimensions'
import { Image } from 'src/ui/atoms'

import type { Props } from './types'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const TextWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white.primary};
  padding-top: ${({ theme }) => theme.paddings.half * 3}px;
  padding-right: ${({ theme }) => theme.paddings.half * 4}px;

  @media (min-width: ${breakpoints.tablet}px) {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 23%;
    min-height: 168px;
  }
`

const Description = styled.p`
  font-size: 14px;
  line-height: 21px;
`

export const AdvantagesItem: React.FC<Props> = ({
  description,
  image,
  title,
}) => {
  return (
    <Wrapper>
      <Image fluid={image} height={414} />
      <TextWrapper>
        <Header size="h5">{title}</Header>
        <HBox height={16} />
        <Description>{description}</Description>
      </TextWrapper>
    </Wrapper>
  )
}
