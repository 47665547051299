import React from 'react'
import { Link } from 'gatsby'

import { styled } from 'src/ui/theme'
import { VBox, HBox } from 'src/ui/layout'
import { Header, Body } from 'src/ui/typography'
import { breakpoints } from 'src/ui/dimensions'
import { InlineButton } from 'src/ui/atoms'

const Wrapper = styled.div`
  display: flex;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: ${({ theme }) => theme.paddings.half * 3}px 0;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  width: 80px;
  height: 80px;
  background-color: ${({ theme }) => theme.colors.gray.bg};
`


type Props = {
  icon: JSX.Element
  title: string
  description: string
  onClick: () => void
}

export const TrainingDirection: React.FC<Props> = ({
  description,
  icon,
  title,
  onClick
}) => {
  return (
    <Wrapper>
      <IconWrapper>{icon}</IconWrapper>
      <VBox width={32} />
      <div>
        <Header size="h4">{title}</Header>
        <HBox height={8} />
        <Body size="primary">{description}</Body>
        <HBox height={8} />
        <InlineButton onClick={onClick}>
          Подробнее
        </InlineButton>
      </div>
    </Wrapper>
  )
} 
