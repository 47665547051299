import React from 'react'

import { Wrapper, HBox } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'
import { Body, Header } from 'src/ui/typography'
import { Touchable } from 'src/ui/atoms'

import { items } from './items'

const Layout = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.divider};
  padding: ${({ theme }) => theme.paddings.main * 3}px 0
    ${({ theme }) => theme.paddings.half * 5}px;
`

const MoreButton = styled.button`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.brand.primary};
`

const Grid = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.paddings.main * 2}px;
`

const DescriptionText = styled.div`
  font-size: 18px;
  line-height: 27px;
  color: ${({ theme }) => theme.colors.black.bg};
`

type Props = {}

export const Faq: React.FC<Props> = () => {
  const theme = useTheme()
  const [isQuestionsVisible, setQuestionsVisible] = React.useState(false)

  return (
    <Layout>
      <Wrapper>
        <Header size="h4">FAQ</Header>
        <HBox height={theme.paddings.half * 3} />
        {!isQuestionsVisible && (
          <Touchable
            onPress={() => setQuestionsVisible(true)}
            hoverColor={theme.colors.brand.secondary}
          >
            <Body color={theme.colors.brand.primary} weight="medium">
              Развернуть
            </Body>
          </Touchable>
        )}
        {isQuestionsVisible && (
          <Grid>
            {items.map(({ description, title, email, link }) => (
              <div>
                <Header size="h5">{title}</Header>
                <HBox height={theme.paddings.main} />
                <DescriptionText>
                  {description}
                  {link ? (
                    <>
                      &nbsp;
                      <a href={link.path}>{link.title}</a>
                    </>
                  ) : null}
                  {email ? (
                    <>
                      &nbsp;
                      <a href={`mailto:${email}`}>{email}</a>
                    </>
                  ) : null}
                </DescriptionText>
              </div>
            ))}
          </Grid>
        )}
      </Wrapper>
    </Layout>
  )
}
