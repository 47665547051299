export const getStories = (images: TImages) => [
  {
    image: images['graduate-stories-1'],
    title: 'Иван, 25 лет',
    subtitle: 'Стал Frontend-разработчиком после стажировки',
    description:
      'У меня не было опыта разработки, только самообучение. Было нелегко, но новые знания стоили усилий. На стажировке я получил опыт коммерческой разработки. Думал, что даже если мне не сделают оффер, то я хотя бы очень неплохо прокачался. Но оффер сделали и теперь я работаю в KODE над интересными проектами.',
  },
  {
    image: images['graduate-stories-2'],
    title: 'Роман, 24 года',
    subtitle: 'Принял оффер после QA-стажировки',
    description:
      'Для меня IT и QA в частности — абсолютно новое направление. Для себя решил, что лучший вариант научиться — это стажировка в KODE, потому что она даёт то, что нужно для работы джуном именно в этой компании. Плюс возможность сразу трудоустроиться и посмотреть на компанию изнутри, составить своё впечатление, пообщаться с практиками, а не теоретиками.',
  },
  {
    image: images['graduate-stories-3'],
    title: 'Александр, 27 лет',
    subtitle: 'Ушёл из госструктуры и стал менеджером проектов в IT',
    description:
      'До стажировки я никогда не работал в IT, но за 4 недели KODE открыла мне новый мир. Менторы здорово балансировали между теорией и практикой. Понравилась их вовлечённость — было видно, что им самим нравится то, что они делают. Я смог зарядиться энергией от лекций и столько же отдать сам при работе над проектом.',
  },
]
