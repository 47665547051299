import React from 'react'

import { Wrapper, HBox } from 'src/ui/layout'
import { Header } from 'src/ui/typography'
import { useTheme, styled } from 'src/ui/theme'

import { AdvantagesItem } from '../../molecules'
import { getItems } from './get-items'

const Grid = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.paddings.main}px;
`

const LabelText = styled.p`
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  text-transform: uppercase;
`

type Props = {
  images: TImages
}

export const AdvantagesList: React.FC<Props> = ({ images }) => {
  const theme = useTheme()
  const items = getItems(images)

  return (
    <Wrapper>
      <LabelText>Наши преимущества</LabelText>
      <HBox height={theme.paddings.half} />
      <Header size="h4">
        Реальные задачи, развитие твоих талантов и драйв
      </Header>
      <HBox height={theme.paddings.half * 3} />
      <Grid>
        {items.map((item) => (
          <AdvantagesItem {...item} />
        ))}
      </Grid>
    </Wrapper>
  )
}
