import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { TrainingDirection as Desk } from './desk'

type TrainingDirectionProps = React.ComponentProps<typeof Desk>

export const TrainingDirection: React.FC<TrainingDirectionProps> = (props) => {
  const { isMobile } = useDimensions()

  return isMobile ? <div>Mob</div> : <Desk {...props} />
}
