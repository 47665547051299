import React from 'react'
import { Query } from 'hr/graphql-types'
import { graphql } from 'gatsby'

import { useDimensions } from 'src/ui/dimensions'
import { RootLayout } from 'src/layouts'
import { HBox } from 'src/ui/layout'
import { Meta } from 'src/features/seo'
import {
  TrainingHero,
  AdvantagesList,
  TrainingStages,
  AboutTraining,
} from 'src/features/training/organisms'
import {
  TrainingDirections,
  OfferBanner,
  Mentors,
  Achievements,
  GraduateStories,
  Notice,
} from 'src/features/training/molecules'
import { Stat, Faq } from 'src/features/training/atoms'

type Props = {} & { data: Query }

const Training: React.FC<Props> = ({ data }) => {
  const { isMobile } = useDimensions()

  const images = data.allFile.edges.reduce<TImages>((acc, img) => {
    return {
      ...acc,
      [img.node.name]: img.node.childImageSharp?.fluid as TFluidObject,
    }
  }, {})

  return (
    <RootLayout>
      <Meta
        title="Профессиональная команда мобильной разработки — KODE"
        description="Работа в сильной и уверенной команде KODE: конференции, митапы, командировки ✔ Офис в Калининграде"
      />

      {isMobile && <HBox height={16} />}
      <TrainingHero images={images} />

      {isMobile ? <HBox height={64} /> : <HBox height={72} />}
      <Notice />

      {isMobile ? <HBox height={64} /> : <HBox height={72} />}
      {/* <TrainingInfo images={images} /> */}
      {isMobile ? <HBox height={48} /> : <HBox height={64} />}
      <AdvantagesList images={images} />
      {isMobile ? <HBox height={48} /> : <HBox height={72} />}
      <Stat />
      {isMobile ? <HBox height={48} /> : <HBox height={64} />}
      {!isMobile && (
        <>
          <TrainingDirections />
          <HBox height={138} />
        </>
      )}
      <TrainingStages images={images} />
      {isMobile ? <HBox height={48} /> : <HBox height={72} />}
      <OfferBanner images={images} />
      {isMobile ? <HBox height={48} /> : <HBox height={136} />}
      <Mentors images={images} />
      {isMobile ? <HBox height={48} /> : <HBox height={136} />}
      <AboutTraining />
      {isMobile ? <HBox height={48} /> : <HBox height={136} />}
      <Achievements images={images} />
      {isMobile ? <HBox height={48} /> : <HBox height={136} />}
      <GraduateStories images={images} />
      {isMobile ? <HBox height={48} /> : <HBox height={72} />}
      <Faq />
      {isMobile ? <HBox height={24} /> : <HBox height={80} />}
    </RootLayout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/training/i" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 1900) {
              srcSet
              base64
              src
              aspectRatio
            }
          }
        }
      }
    }
  }
`

export default Training
