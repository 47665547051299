import React from 'react'

import { Wrapper, HBox } from 'src/ui/layout'
import { useTheme, styled } from 'src/ui/theme'
import { Header, Body } from 'src/ui/typography'

import { items } from './items'

const ContentWrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.paddings.main * 4}px 0 ${theme.paddings.main * 4.5}px`};
  background-color: ${({ theme }) => theme.colors.gray.divider};
`

const Grid = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.paddings.main * 3}px;
`

const DescriptionText = styled(Body)`
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.black.bg};
`

type Props = {}

export const Faq: React.FC<Props> = () => {
  const theme = useTheme()

  return (
    <ContentWrapper>
      <Wrapper>
        <Header size="h2">FAQ</Header>
        <HBox height={theme.paddings.half * 3} />
        <Grid>
          {items.map(({ description, title, email, link }) => (
            <div>
              <Header size="h5">{title}</Header>
              <HBox height={theme.paddings.main} />
              <DescriptionText>
                {description}
                {link ? (
                  <>
                    &nbsp;
                    <a href={link.path}>{link.title}</a>
                  </>
                ) : null}
                {email ? (
                  <>
                    &nbsp;
                    <a href={`mailto:${email}`}>{email}</a>
                  </>
                ) : null}
              </DescriptionText>
            </div>
          ))}
        </Grid>
      </Wrapper>
    </ContentWrapper>
  )
}
