import React from 'react'

import { Wrapper, VBox, HBox } from 'src/ui/layout'
import { Body, Header } from 'src/ui/typography'
import { styled, useTheme } from 'src/ui/theme'
import { Image } from 'src/ui/atoms'

import { getStories } from './get-stories'

const Grid = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.paddings.main * 4}px;
`

const Row = styled.div`
  display: flex;
`

const ImageWrapper = styled.div`
  min-width: 230px;
  width: 230px;
`

type Props = {
  images: TImages
}

export const GraduateStories: React.FC<Props> = ({ images }) => {
  const theme = useTheme()
  const stories = getStories(images)

  return (
    <Wrapper>
      <Header size="h2">Как это было</Header>
      <HBox height={theme.paddings.half * 3} />
      <Body>Истории выпускников, которые получили работу в команде KODE</Body>
      <HBox height={theme.paddings.main * 4} />
      <Grid>
        {stories.map(({ description, image, subtitle, title }) => (
          <Row>
            <ImageWrapper>
              <Image fluid={image} height={228} />
            </ImageWrapper>
            <VBox width={theme.paddings.main * 2} />
            <div>
              <Header size="h4">{title}</Header>
              <HBox height={theme.paddings.half} />
              <Body weight="medium">{subtitle}</Body>
              <HBox height={theme.paddings.main} />
              <Body>{description}</Body>
            </div>
          </Row>
        ))}
      </Grid>
    </Wrapper>
  )
}
