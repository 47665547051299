import React from 'react'

import { Wrapper, HBox } from 'src/ui/layout'
import { Header } from 'src/ui/typography'
import { useTheme, styled } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'

import { TrainingStage } from '../../molecules'
import { getItems } from './get-stages'

const TABLET_MAX_GRID_COLS = 2

const Content = styled.div`
  margin-right: -${({ theme }) => theme.paddings.main * 4}px;
  width: ${({ theme }) => `calc(100% + ${theme.paddings.main * 4}px)`};
`

const Grid = styled.div<{ $cols: number }>`
  display: grid;
  grid-template-columns: ${({ $cols }) => `repeat(${$cols}, 1fr)`};
  grid-gap: ${({ theme }) => theme.paddings.half}px;
`

type TrainingStageProps = React.ComponentProps<typeof TrainingStage>

type Props = {
  images: TImages
}

export const TrainingStages: React.FC<Props> = ({ images }) => {
  const theme = useTheme()
  const items = getItems(images)

  const getGridCols = (cols: number) => {
    if (typeof window !== 'undefined' && window.innerWidth < breakpoints.tablet && cols > TABLET_MAX_GRID_COLS) {
      return TABLET_MAX_GRID_COLS
    }

    return cols
  }

  return (
    <Wrapper>
      <Header size="h2">Как проходит обучение</Header>
      <HBox height={theme.paddings.main * 4} />
      <Content>
        <Grid $cols={1}>
          {items.map((row) => (
            <Grid $cols={getGridCols(row.length)}>
              {row.map((item) => (
                <TrainingStage {...item} />
              ))}
            </Grid>
          ))}
        </Grid>
      </Content>
    </Wrapper>
  )
}
