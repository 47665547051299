import React from 'react'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const TrainingHero: React.FC<Props> = ({ images }) => (
  <Hero
    background={images['training-hero']}
    title="Начни карьеру в IT на стажировке KODE"
    adornment
  >
    <HBox />
    <Body size="secondary" style={{ fontWeight: 400 }}>
      Научись разрабатывать цифровые продукты для государства и бизнеса вместе с
      нами.
    </Body>
  </Hero>
)
