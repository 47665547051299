import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { OfferBanner as Desk } from './desk'
import { OfferBanner as Mob } from './mob'

type Props = {
  images: TImages
}
export const OfferBanner: React.FC<Props> = ({ images }) => {
  const { isMobile } = useDimensions()
  const Component = isMobile ? Mob : Desk

  return <Component images={images} />
}
