export const items = [
  {
    title: 'заявок на участие ежегодно',
    stat: '>500',
  },
  {
    title: 'лет проводим стажировки',
    stat: '10',
  },
  {
    title: 'выпускников, успешно окончивших обучение',
    stat: '>150',
  },
]
