import React from 'react'

import { Wrapper, HBox } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'
import { Header } from 'src/ui/typography'

import { getItemsForMobile } from './get-stages'
import { TrainingStage } from '../../molecules/training-stage'

const Grid = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.paddings.half}px;
`

type Props = { images: TImages }

export const TrainingStages: React.FC<Props> = ({ images }) => {
  const theme = useTheme()
  const items = getItemsForMobile(images)

  return (
    <Wrapper>
      <Header size="h4">Как проходит обучение</Header>
      <HBox height={theme.paddings.main * 2} />
      <Grid>
        {items.map((item) => (
          <TrainingStage {...item} />
        ))}
      </Grid>
    </Wrapper>
  )
}
