import React from 'react'

import { styled } from 'src/ui/theme'
import { Wrapper, HBox } from 'src/ui/layout'
import { Header } from 'src/ui/typography'
import { breakpoints } from 'src/ui/dimensions'

import { AdvantagesItem } from '../../molecules'
import { getItems } from './get-items'

const Title = styled.h2`
  font-size: 14px;
  line-height: 17px;
  font-weight: 900;
  text-transform: uppercase;
`

const ImagesWrapper = styled.div`
  width: ${({ theme }) => `calc(100% + ${theme.paddings.main * 4}px)`};
  margin-right: -${({ theme }) => theme.paddings.main * 4}px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: ${({ theme }) => theme.paddings.main * 4}px;

  @media (max-width: ${breakpoints.tablet}px) {
    grid-column-gap: ${({ theme }) => theme.paddings.main * 1}px;
  }
`

type Props = {
  images: TImages
}

export const AdvantagesList: React.FC<Props> = ({ images }) => {
  const items = getItems(images)

  return (
    <Wrapper>
      <Title>Наши преимущества</Title>
      <HBox height={25} />
      <Header size="h2">
        Реальные задачи, развитие твоих талантов и драйв
      </Header>
      <HBox height={64} />
      <ImagesWrapper>
        {items.map((item) => (
          <AdvantagesItem {...item} />
        ))}
      </ImagesWrapper>
    </Wrapper>
  )
}
