import React from 'react'

import { TrainingStage } from '../../molecules'

type TrainingStageProps = React.ComponentProps<typeof TrainingStage>

export const getItems = (images: TImages): TrainingStageProps[][] => [
  [
    {
      number: 1,
      title: 'Приём заявок в марте и сентябре',
      image: images['stages-1'],
      variant: 'h4',
    },
    {
      number: 2,
      title: 'Выполнение тестового задания',
      image: images['stages-2'],
      variant: 'h4',
    },
    {
      number: 3,
      title: 'Отбор лучших кандидатов',
      image: images['stages-3'],
      variant: 'h4',
    },
    {
      number: 4,
      title: 'Собеседование с каждым участником',
      image: images['stages-4'],
      variant: 'h4',
    },
  ],
  [
    {
      number: 5,
      title: 'Стажировка',
      image: images['stages-5'],
      variant: 'h3',
    },
    {
      number: 6,
      title: 'Защита дипломного проекта',
      image: images['stages-6'],
      variant: 'h3',
    },
  ],
  [
    {
      number: 7,
      title: 'Выпускной',
      image: images['stages-7'],
      variant: 'h2',
    },
  ],
]

export const getItemsForMobile = (images: TImages) => {
  const items = getItems(images)

  return ([] as TrainingStageProps[]).concat(...items)
}
