export const conditions = [
  {
    number: 1,
    description: 'легко и быстро пройти адаптацию',
  },
  {
    number: 2,
    description: 'узнать больше о компании о познакомиться с командой',
  },
  {
    number: 3,
    description: 'погрузиться в специфику процессов KODE',
  },
  {
    number: 4,
    description: 'поработать на действующих проектах',
  },
]
