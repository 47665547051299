import React from 'react'

import { Image } from 'src/ui/atoms'
import { Header } from 'src/ui/typography'
import { useTheme, styled } from 'src/ui/theme'

import type { Props } from './types'

const Wrapper = styled.div`
  position: relative;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) =>
    `${theme.paddings.half * 2}px ${theme.paddings.half * 3}px`};
`

export const TrainingStage: React.FC<Props> = ({ image, number, title }) => {
  const theme = useTheme()

  return (
    <Wrapper>
      <Image fluid={image} height={144} />
      <Content>
        <Header size="h3" color={theme.colors.white.primary}>
          {number}
        </Header>
        <Header size="h5" color={theme.colors.white.primary}>
          {title}
        </Header>
      </Content>
    </Wrapper>
  )
}
