import React from 'react'

import { Wrapper, HBox } from 'src/ui/layout'
import { useTheme, styled } from 'src/ui/theme'
import { Header, Body } from 'src/ui/typography'

import { items } from './items'

const StatWrapper = styled.div`
  padding: ${({ theme }) => theme.paddings.main * 2}px 0;
  background-color: ${({ theme }) => theme.colors.gray.divider};
`

const Grid = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.paddings.main * 2}px;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 247px;
  justify-self: center;
`

type Props = {}

export const Stat: React.FC<Props> = () => {
  const theme = useTheme()

  return (
    <StatWrapper>
      <Wrapper>
        <Grid>
          {items.map(({ stat, title }) => (
            <Item>
              <Header size="h3">{stat}</Header>
              <HBox height={theme.paddings.half} />
              <Body size="secondary" style={{ textAlign: 'center' }}>
                {title}
              </Body>
            </Item>
          ))}
        </Grid>
      </Wrapper>
    </StatWrapper>
  )
}
