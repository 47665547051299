import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import type { Props } from './types'
import { TrainingStage as Desk } from './desk'
import { TrainingStage as Mob } from './mob'

export const TrainingStage: React.FC<Props> = (props) => {
  const { isMobile } = useDimensions()
  const Component = isMobile ? Mob : Desk

  return <Component {...props} />
}
