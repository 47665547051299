import React, { useState } from 'react'

import { styled } from 'src/ui/theme'
import { Header, Body } from 'src/ui/typography'
import { HBox, Wrapper } from 'src/ui/layout'
import { Icons } from 'src/ui/icons'
import { Button } from 'src/ui/molecules'
import { breakpoints } from 'src/ui/dimensions'
import { Modal } from 'src/ui/organisms'

import { TrainingDirection } from '../../atoms'
import type { TDirections } from './types'
import { items as modalItems } from './items'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${({ theme }) => theme.paddings.main * 2}px;

  @media (max-width: ${breakpoints.tablet}px) {
    grid-template-columns: auto;
  }
`

const List = styled.ul`
  list-style: inside;
`

const ProgramItem = styled.li`
  list-style: inside;
`

const items: (Omit<TrainingDirectionProps, 'onClick'> & {
  direction: TDirections
})[] = [
  {
    direction: 'ios',
    title: 'iOS-разработка',
    description:
      'Освоишь основы разработки, пройдешь путь от создания проекта до публикации приложения.',
    icon: <Icons.Ios />,
  },
  {
    direction: 'frontend',
    title: 'Frontend-разработка',
    description:
      'Научишься проектировать интерфейсы и создавать эффектные цифровые продукты на React.',
    icon: <Icons.Frontend />,
  },
  {
    direction: 'design',
    title: 'UX/UI-дизайн',
    description:
      'Погрузишься в настоящую разработку дизайна приложений и научишься создавать интерфейсы мобильных продуктов.',
    icon: <Icons.Design />,
  },
  {
    direction: 'qa',
    title: 'Quality Assurance',
    description:
      'Получишь всё для старта карьеры тестировщика и сможешь обеспечивать качество цифрового продукта на всех этапах разработки.',
    icon: <Icons.QualityAssurance />,
  },
  {
    direction: 'android',
    title: 'Android-разработка',
    description:
      'Освоишь разработку на Kotlin под самую популярную мобильную платформу.',
    icon: <Icons.Android />,
  },
  {
    direction: 'backend',
    title: 'Backend-разработка',
    description:
      'Заглянешь в закулисье web и узнаешь, что на самом деле находится по ту сторону клиентских приложений.',
    icon: <Icons.Backend />,
  },
  {
    direction: 'analytics',
    title: 'Аналитика',
    description:
      'Научишься работать с информацией, делать прогнозы на основе данных и помогать принимать решения в бизнесе.',
    icon: <Icons.Analytics />,
  },
  {
    direction: 'management',
    title: 'Project management',
    description:
      'Научишься управлять проектами на всех этапах — от коммуникации с заказчиком и сборки прототипа до реализации и поддержки.',
    icon: <Icons.ProjectManagement />,
  },
  {
    direction: 'recruiting',
    title: 'Рекрутинг',
    description:
      'Погрузишься в специфику IT-рынка и поймёшь, как искать идеальных кандидатов: от обработки запроса — до отправления оффера.',
    icon: <Icons.Recruiting />,
  },
  {
    direction: 'vui',
    title: 'Разговорный дизайн',
    description:
      'Научишься проектировать разговорные интерфейсы и помогать бизнесу использовать ассистентов, чат-боты и телефонные системы по максимуму.',
    icon: <Icons.VoiceUi />,
  },
]

type TrainingDirectionProps = React.ComponentProps<typeof TrainingDirection>

type Props = {}

export const TrainingDirections: React.FC<Props> = () => {
  const [
    selectedDirection,
    setSelectedDirection,
  ] = useState<TDirections | null>(null)

  return (
    <>
      <div>
        <Wrapper>
          <Header size="h2">Направления стажировки</Header>
          <HBox height={64} />
          <Grid>
            {items.map(({ direction, ...item }) => (
              <TrainingDirection
                onClick={() => setSelectedDirection(direction)}
                {...item}
              />
            ))}
          </Grid>
        </Wrapper>
      </div>
      {selectedDirection !== null && (
        <Modal
          color="white"
          modalIsVisible={selectedDirection !== null}
          onClose={() => setSelectedDirection(null)}
          title={modalItems[selectedDirection].title}
        >
          <Body>{modalItems[selectedDirection].subtitle}</Body>
          <HBox height={24} />
          <Header size="h5">Программа:</Header>
          <HBox height={24} />
          <List>
            {modalItems[selectedDirection].program.map((item, index, arr) => (
              <>
                <ProgramItem>{item}</ProgramItem>
                {index !== arr.length - 1 && <HBox height={8} />}
              </>
            ))}
          </List>
        </Modal>
      )}
    </>
  )
}
