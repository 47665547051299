import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { Stat as Desk } from './desk'
import { Stat as Mob } from './mob'

type Props = {}
export const Stat: React.FC<Props> = () => {
  const { isMobile } = useDimensions()
  const Component = isMobile ? Mob : Desk

  return <Component />
}
