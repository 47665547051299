import React from 'react'

import { SwiperSlide } from 'swiper/react/swiper-react.js'

import { Wrapper, HBox } from 'src/ui/layout'
import { Body, Header, ClippedText } from 'src/ui/typography'
import { useTheme } from 'src/ui/theme'
import { SwiperSlider } from 'src/ui/organisms'
import { Image, Touchable } from 'src/ui/atoms'

import { useClippedCollection } from 'src/shared/hooks'
import { getStories } from './get-stories'

type Props = { images: TImages }

export const GraduateStories = ({ images }: Props) => {
  const theme = useTheme()
  const stories = getStories(images)
  const { clipped, setClippedByIndex } = useClippedCollection(stories)

  return (
    <Wrapper>
      <Header size="h4">Как это было</Header>
      <HBox height={theme.paddings.half} />
      <Body>Истории выпускников, которые получили работу в команде KODE</Body>
      <HBox height={theme.paddings.half} />
      <SwiperSlider>
        {stories.map(({ description, image, subtitle, title }, index) => (
          <SwiperSlide key={index}>
            <Image fluid={image} height={343} />
            <HBox height={theme.paddings.main} />
            <Header size="h5">{title}</Header>
            <HBox height={theme.paddings.half} />
            <Body weight="medium">{subtitle}</Body>
            <HBox height={theme.paddings.half} />
            <ClippedText $clipped={clipped[index]}>{description}</ClippedText>
            {clipped[index] && (
              <>
                <HBox height={theme.paddings.half} />
                <Touchable
                  onPress={() => setClippedByIndex(index)}
                  hoverColor={theme.colors.brand.secondary}
                >
                  <Body color={theme.colors.brand.primary} weight="medium">
                    Подробнее
                  </Body>
                </Touchable>
              </>
            )}
          </SwiperSlide>
        ))}
      </SwiperSlider>
    </Wrapper>
  )
}
