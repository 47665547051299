export const getMentors = (images: TImages) => [
  {
    name: 'Ирина Косицына',
    position: 'Тимлид отдела рекрутинга',
    description:
      'В IT около четырех лет, имеет опыт работы в прямых продажах. Быстро нашла целую команду Java-разработчиков для нового офиса KODE в Питере. На стажировке поможет раскрыть твой потенциал в рекрутинге.',
    image: images['mentors-1'],
  },
  {
    name: 'Наталья Семёнова',
    position: 'Тимлид Frontend-отдела',
    description:
      'Больше пяти лет в IT, последний год качает скилл в государственных и образовательных проектах. Считает важным заботиться о пользовательском опыте и стремиться к совершенству деталей. Легко находит общий язык с фронтендерами любого грейда.',
    image: images['mentors-2'],
  },
  {
    name: 'Вадим Кузенков',
    position: 'Директор проектного офиса',
    description:
      'Тимлид тимлидов: руководит портфелем проектов KODE. Отлично разбирается в людях, методиках управления и командной эффективности. Противник карго-культа и адепт осознанности.',
    image: images['mentors-3'],
  },
  {
    name: 'Антон Петраков',
    position: 'iOS-разработчик',
    description:
      'Больше 11 лет в IT — из них семь лет занимается iOS-разработкой. Работал на проектах в сфере коммерции, авиа и банкинга. Считает, что главное в работе — решать сложные задачи простым способом.',
    image: images['mentors-4'],
  },
  {
    name: 'Илья Некрасов',
    position: 'Тимлид Android-отдела',
    description:
      'В IT больше пяти лет. Работал над сложными проектами для банков и авиакомпаний. Регулярно изучает новые технологии и стек, в том числе Flutter и backend-разработку. Стремится к профессиональному развитию и вдохновляет на это свою команду.',
    image: images['mentors-5'],
  },
  {
    name: 'Мартин Эрлер',
    position: 'Тимлид дизайн-отдела',
    description:
      'Занимается проектами в сферах финтеха, сервисов и медицины. Его профессиональные интересы: дизайн-системы и процессы, пользовательский опыт и проектирование взаимодействия. Руководитель внутренних программ адаптации дизайнеров в KODE.',
    image: images['mentors-6'],
  },
  {
    name: 'Евгения Адоняева',
    position: 'Тимлид Backend-отдела',
    description:
      'Работает в IT 12 лет. Возглавляет сразу 3 направления: Java, Python и Golang. В людях особенно ценит инициативность и умение работать в команде.',
    image: images['mentors-7'],
  },
  {
    name: 'Владимир Румянцев',
    position: 'Руководитель отдела аналитики',
    description:
      'Оттачивает мастерство аналитика в финтехе, авиации и ритейле. Эффективно управляет отделом и внедряет системное мышление в командах. Руководит стажировкой по аналитике и решает, кто получит оффер в компанию.',
    image: images['mentors-8'],
  },
  {
    name: 'Пётр Жуков',
    position: 'Тимлид QA-отдела',
    description:
      'Мастер декомпозиции, упорядочивания и масштабирования процессов. Управляет командой, которая контролирует качество банковских, инвестиционных и медицинских проектов. Главной заслугой считает эффективный рост специалистов под его руководством. В свободное время смотрит научно-популярные фильме о космосе.',
    image: images['mentors-9'],
  },
]
