export const items = [
  {
    title: 'Чем я буду заниматься?',
    description:
      'Сначала познакомишься с наставником и всеми процессами в компании. Узнаешь о своём проекте и роли в нём. Дальше — два раза в неделю будешь посещать лекции и воркшопы, готовить домашние задания и работать над проектом.',
  },
  {
    title: 'Когда будет следующая стажировка?',
    description:
      'Обучение в компании стартует два раза в год весной и осенью и длится один месяц. Приём заявок открываем за две–три недели до начала. Прием заявок всегда проходит на сайте',
    link: {
      title: 'edu.kode.ru',
      path: 'http://edu.kode.ru/',
    },
  },
  {
    title: 'Как попасть на стажировку?',
    description:
      'После того как откроется приём заявок, зарегистрируйся на нужное направление и дождись тестового задания. Если успешно с ним справишься, тебя пригласят на собеседование и стажировку.',
  },
  {
    title: 'Оплачивается ли стажировка?',
    description:
      'Нет, потому что мы не подключаем стажёров на реальные проекты компании. Вместо этого делимся опытом коммерческой разработки, а лучшим выпускникам делаем оффер.',
  },
  {
    title: 'Можно ли сочетать стажировку и работу?',
    description:
      'Да, конечно! Твои менторы тоже работают в течение дня, поэтому все занятия будут проходить вечером. Заложи около 20 часов в неделю на обучение — для вечерних занятий, домашних заданий и подготовки финального проекта.',
  },
  {
    title: 'Можно ли пройти стажировку удалённо?',
    description:
      'Мы любим проводить стажировки в калининградском офисе. Это помогает быстрее и глубже погрузиться в процессы компании и познакомиться с командой. На прошлые стажировки к нам приезжали из Новосибирска, Уфы и Казани, многие даже остались. Мы не оплачиваем стажёрам проезд и проживание, но помогаем с релокацией тем, кто получает офферы.\u000a\u000aЕсли стажировка не предполагает удалённый формат и ты не можешь приехать — всё равно откликайся и присылай тестовое. Если выполнишь его на высоком уровне, мы придумаем, как тебя подключить 😉.',
  },
  {
    title: 'Как подготовиться к стажировке?',
    description:
      'После подачи заявки на стажировку тебе придёт список полезных материалов. Они помогут легко разобраться с тестовым и освоиться в направлении.',
  },

  {
    title: 'Я подал заявку, но мне до сих пор не ответили. Почему?',
    description:
      'Мы получаем много заявок и тщательно их изучаем. Следи за сообщениями и проверяй папку «Спам». А если ты так и не получил ответ, пиши на',
    email: 'edu@kode.ru',
  },
]
