import React from 'react'

import { Hero } from 'src/ui/organisms'
import { styled, useTheme } from 'src/ui/theme'
import { HBox } from 'src/ui/layout'
import { Body } from 'src/ui/typography'

import type { Props } from './types'

const Wrapper = styled.div`
  margin: ${({ theme }) => `0 -${theme.paddings.main}px`};
`

export const AdvantagesItem: React.FC<Props> = ({
  description,
  image,
  title,
}) => {
  const theme = useTheme()

  return (
    <Wrapper>
      <Hero background={image} title={title} titleSize="h5" textHeight={218}>
        <HBox height={theme.paddings.half * 3} />
        <Body>{description}</Body>
      </Hero>
    </Wrapper>
  )
}
