import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { TrainingDirections as Desk } from './desk'

type Props = {}

export const TrainingDirections: React.FC<Props> = () => {
  const { isMobile } = useDimensions()

  return isMobile ? <div>Mob</div> : <Desk />
}
