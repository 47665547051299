import React from 'react'

import { HBox, VBox, Wrapper } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'
import { Body, Header } from 'src/ui/typography'

import { conditions } from './conditions'

const Grid = styled.div`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.paddings.main}px;
`

const Row = styled.div`
  display: flex;
`

const ConditionDescription = styled.div`
  font-size: 16px;
  line-height: 28px;
`

const Description = styled.div`
  padding: ${({ theme }) => theme.paddings.main}px 0;
  background-color: ${({ theme }) => theme.colors.gray.divider};
`

type Props = {}

export const Achievements: React.FC<Props> = () => {
  const theme = useTheme()

  return (
    <>
      <Wrapper>
        <HBox height={theme.paddings.half} />
        <Header size="h4">Достижения</Header>
        <HBox height={theme.paddings.half} />
        <Body>
          Стажировки KODE — первый шаг в вертикальной программе обучения
          специалистов. За всю историю мы провели 10 стажировок, 82% стажеров
          остаются работать в KODE.
        </Body>
        <HBox height={theme.paddings.main * 2} />
        <Grid>
          {conditions.map(({ description, number }) => (
            <Row>
              <Header size="h4" color={theme.colors.brand.primary}>
                {number}
              </Header>
              <VBox width={40} />
              <ConditionDescription>{description}</ConditionDescription>
            </Row>
          ))}
        </Grid>
        <HBox height={theme.paddings.half * 2} />
      </Wrapper>
      <Description>
        <Wrapper>
          <Body>
            В разгар пандемии, весной 2020 года, мы впервые провели
            онлайн-стажировку по 3 направлениям: iOS-разработка, дизайн и QA. За
            неё команда KODE получила серебро на престижной премии{' '}
            <Body weight="medium" as="span">
              Tagline Summer Awards
            </Body>{' '}
            в номинации Online-мероприятия.
          </Body>
        </Wrapper>
      </Description>
    </>
  )
}
