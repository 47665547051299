import React from 'react'

import { Button } from 'src/ui/molecules'
import { HeroDesktopSecondary } from 'src/ui/organisms'
import { HBox } from 'src/ui/layout'
import { Body, Header } from 'src/ui/typography'
import { styled } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'

const Content = styled.div`
  max-width: 512px;
`

const StyledButton = styled(Button)`
  width: 260px;
`

type Props = {
  images: TImages
}

export const TrainingHero: React.FC<Props> = ({ images }) => (
  <HeroDesktopSecondary
    contentPosition="bottom-left"
    background={images['training-hero']}
    adornment={true}
    height={630}
    contentWidth={typeof window !== 'undefined' && window.innerWidth < breakpoints.desktopXS ? 56 : undefined}
  >
    <Content>
      <Header size={typeof window !== 'undefined' && window.innerWidth < breakpoints.tablet ? 'h3' : 'h2'} as="h1">
        Начни карьеру в IT на стажировке KODE
      </Header>
      <HBox height={24} />
      <Body>
        Научись разрабатывать цифровые продукты для государства и бизнеса вместе
        с нами.
      </Body>
    </Content>
  </HeroDesktopSecondary>
)
