import React from 'react'

import { Wrapper, HBox } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'
import { Image } from 'src/ui/atoms'
import { Header, Body } from 'src/ui/typography'
import { breakpoints } from 'src/ui/dimensions'

import { getMentors } from './get-items'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${({ theme }) => theme.paddings.main * 3}px;

  @media (max-width: ${breakpoints.tablet}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

type Props = {
  images: TImages
}

export const Mentors: React.FC<Props> = ({ images }) => {
  const theme = useTheme()
  const mentors = getMentors(images)

  return (
    <Wrapper>
      <Header size="h2">Наставники</Header>
      <HBox height={theme.paddings.half * 3} />
      <Body size="primary">
        Сопровождают стажёров в течение всего обучения: проводят лекции и
        воркшопы, проверяют домашние задания и дают фидбэк, помогают прокачивать
        hard и soft skills.
      </Body>
      <HBox height={theme.paddings.main * 4} />
      <Grid>
        {mentors.map(({ description, image, name, position }) => (
          <div>
            <Image fluid={image} height={530} />
            <HBox height={theme.paddings.main * 2} />
            <Header size="h4">{name}</Header>
            <HBox height={theme.paddings.half} />
            <Body weight="medium">{position}</Body>
            <HBox height={theme.paddings.main} />
            <Body>{description}</Body>
          </div>
        ))}
      </Grid>
    </Wrapper>
  )
}
