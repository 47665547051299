import React from 'react'

import { SwiperSlide } from 'swiper/react/swiper-react.js'

import { Wrapper, HBox } from 'src/ui/layout'
import { Header, Body, ClippedText } from 'src/ui/typography'
import { useTheme } from 'src/ui/theme'
import { SwiperSlider } from 'src/ui/organisms'
import { Image, Touchable } from 'src/ui/atoms'

import { useClippedCollection } from 'src/shared/hooks'
import { getMentors } from './get-items'

type Props = {
  images: TImages
}

export const Mentors = ({ images }: Props) => {
  const theme = useTheme()
  const mentors = getMentors(images)
  const { clipped, setClippedByIndex } = useClippedCollection(mentors)

  return (
    <Wrapper>
      <Header size="h4">Наставники</Header>
      <HBox height={theme.paddings.half} />
      <Body>
        Сопровождают стажёров в течение всего обучения: проводят лекции и
        воркшопы, проверяют домашние задания и дают фидбэк, помогают прокачивать
        hard и soft skills.
      </Body>
      <HBox height={theme.paddings.half} />
      <SwiperSlider>
        {mentors.map(({ description, image, name, position }, index) => (
          <SwiperSlide key={index}>
            <Image fluid={image} height={343} />
            <HBox height={theme.paddings.main} />
            <Header size="h5">{name}</Header>
            <HBox height={theme.paddings.half} />
            <Body weight="medium">{position}</Body>
            <HBox height={theme.paddings.half} />
            <ClippedText $clipped={clipped[index]}>{description}</ClippedText>
            <HBox height={theme.paddings.half} />
            {clipped[index] && (
              <Touchable
                onPress={() => setClippedByIndex(index)}
                hoverColor={theme.colors.brand.secondary}
              >
                <Body color={theme.colors.brand.primary} weight="medium">
                  Подробнее
                </Body>
              </Touchable>
            )}
          </SwiperSlide>
        ))}
      </SwiperSlider>
    </Wrapper>
  )
}
