import React from 'react'

import { HBox, Wrapper } from 'src/ui/layout'
import { styled, useTheme } from 'src/ui/theme'
import { Body, Header } from 'src/ui/typography'

import { conditions } from './conditions'

const ConditionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: ${({ theme }) => theme.paddings.main}px;
`

const ConditionDescription = styled.div`
  font-size: 18px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black.primary};
`

const AchievementsDescription = styled.div`
  padding: ${({ theme }) => theme.paddings.main * 4}px 0;
  background-color: ${({ theme }) => theme.colors.gray.divider};
`

const StyledHeader = styled(Header)`
  color: ${({ theme }) => theme.colors.black.secondary};
  line-height: 1.5;
`

const TaglineText = styled.span`
  color: ${({ theme }) => theme.colors.black.primary};
`

type Props = {
  images: TImages
}

export const Achievements: React.FC<Props> = ({ images }) => {
  const theme = useTheme()

  return (
    <>
      <Wrapper>
        <Header size="h2">Достижения</Header>
        <HBox height={theme.paddings.half * 3} />
        <Body>
          Стажировки KODE — первый шаг в вертикальной программе обучения
          специалистов. За всю историю мы провели 10 стажировок, 82% стажеров
          остаются работать в KODE.
        </Body>
        <HBox height={theme.paddings.main * 4} />
        <HBox height={theme.paddings.main * 4} />
        <Header size="h4">
          Мы создаём комфортные условия, которые помогают стажёру ещё до выхода
          на работу:
        </Header>
        <HBox height={theme.paddings.main * 4} />
        <ConditionsGrid>
          {conditions.map(({ description, number }) => (
            <div>
              <Header size="h3" color={theme.colors.brand.primary}>
                {number}
              </Header>
              <HBox height={theme.paddings.main} />
              <ConditionDescription>{description}</ConditionDescription>
            </div>
          ))}
        </ConditionsGrid>
      </Wrapper>
      <HBox height={theme.paddings.main * 4.5} />
      <AchievementsDescription>
        <Wrapper>
          <StyledHeader size="h4">
            В разгар пандемии, весной 2020 года, мы впервые провели
            онлайн-стажировку по 3 направлениям: iOS-разработка, дизайн и QA. За
            неё команда KODE получила серебро на престижной премии{' '}
            <TaglineText>Tagline Summer Awards</TaglineText> в номинации
            Online-мероприятия.
          </StyledHeader>
        </Wrapper>
      </AchievementsDescription>
    </>
  )
}
