import React from 'react'

import { Wrapper, HBox } from 'src/ui/layout'
import { useTheme, styled } from 'src/ui/theme'
import { Header } from 'src/ui/typography'
import { Image } from 'src/ui/atoms'

const Layout = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.divider};
  padding: ${({ theme }) => theme.paddings.half * 3}px 0;
`

type Props = {
  images: TImages
}

export const OfferBanner: React.FC<Props> = ({ images }) => {
  const theme = useTheme()

  return (
    <Layout>
      <Wrapper>
        <Header size="h5">Лучшие стажёры получают приглашение на работу</Header>
        <HBox height={theme.paddings.main * 2} />
        <Image fluid={images['offer-banner']} height={163} />
      </Wrapper>
    </Layout>
  )
}
