import React from 'react'

import { HBox, Wrapper } from 'src/ui/layout'
import { styled } from 'src/ui/theme'

import { items } from './items'

const TrainingInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.paddings.half * 3}px 0;
  background-size: cover;
`

const TrainingInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white.primary};
  padding: ${({ theme }) => theme.paddings.main * 2}px 0;
`

const Grid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: ${({ theme }) => theme.paddings.main * 4}px;
`

const InfoNumeral = styled.p`
  font-family: ${({ theme }) => theme.fonts.headers};
  font-size: 72px;
  line-height: 1;
  font-weight: 800;
`

const InfoItemTitle = styled.p`
  font-size: 18px;
  line-height: 27px;
  text-align: center;
`

type Props = {
  images: TImages
}

export const TrainingInfo: React.FC<Props> = ({ images }) => {
  return (
    <TrainingInfoWrapper
      style={{
        backgroundImage: `url(${images['bg'].src})`,
      }}
    >
      <Wrapper>
        <Grid>
          {items.map(({ numeral, title }) => (
            <TrainingInfoItem>
              <InfoNumeral>{numeral}</InfoNumeral>
              <HBox height={8} />
              <InfoItemTitle>{title}</InfoItemTitle>
            </TrainingInfoItem>
          ))}
        </Grid>
      </Wrapper>
    </TrainingInfoWrapper>
  )
}
